import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submit"]

  connect() {
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    const form = this.element
    const requiredFields = form.querySelectorAll("[required]")
    let allFilled = true

    requiredFields.forEach(field => {
      if (!field.value.trim()) {
        allFilled = false
      }
    })

    if (allFilled) {
      this.submitTarget.classList.remove("disabled-btn")
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.classList.add("disabled-btn")
      this.submitTarget.disabled = true
    }
  }

  checkFields() {
    this.toggleSubmitButton()
  }
}
