import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    setTimeout(this.dismiss, 5000)
  }

  dismiss() {
    document.querySelectorAll(".flash").forEach(el => el.remove());
  }
}
